import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  colors,
  useTheme
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import AnalyticService from 'src/services/AnalyticService';

const ChartBarActivity = (props) => {
  const theme = useTheme();
  const [dataWaiting, setDataWaiting] = useState([]);
  const [dataApproved, setDataApproved] = useState([]);
  const [dataRejected, setDataRejected] = useState([]);
  const [dataLabels, setDataLabels] = useState([]);

  const data = {
    datasets: [
      {
        backgroundColor: colors.grey[300],
        data: dataWaiting,
        label: 'Waiting',
      },
      {
        backgroundColor: colors.green[300],
        data: dataApproved,
        label: 'Approved'
      },
      {
        backgroundColor: colors.red[300],
        data: dataRejected,
        label: 'Rejected'
      }
    ],
    labels: dataLabels
  };

  const options = {
    animation: false,
    cornerRadius: 0,
    layout: { padding: 0 },
    legend: { display: true, position: 'bottom', align: 'center' },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            stepSize: 1
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const fetchData = async () => {
    try {
      const response = await AnalyticService.sumActLast7Today();
      const dataChartBar = response.data.data;
      const actRptKaryApproved = dataChartBar.map((obj) => obj.actRptKaryApproved);
      const actRptKaryRejected = dataChartBar.map((obj) => obj.actRptKaryRejected);
      const actRptKaryWaitingResponse = dataChartBar.map((obj) => obj.actRptKaryWaitingResponse);
      const tglActRpt = dataChartBar.map((obj) => moment(obj.tgl_act_rpt).format('DD MMMM'));

      setDataApproved(actRptKaryApproved.reverse());
      setDataRejected(actRptKaryRejected.reverse());
      setDataWaiting(actRptKaryWaitingResponse.reverse());
      setDataLabels(tglActRpt.reverse());
    } catch (error) {
      console.log('Tidak dapat memuat data');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card {...props}>
      <CardHeader title="Activity Reports: Last 7 Days" />
      <Divider />
      <CardContent sx={{ height: '85%' }}>
        <Bar
          data={data}
          options={options}
        />
      </CardContent>
    </Card>
  );
};

export default ChartBarActivity;
