import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ListIcon from '@material-ui/icons/List';
import UserServices from 'src/services/UserServices';
import { toast } from 'react-toastify';
import Modal from 'src/components/Modal';
import UserForm from 'src/components/user/UserForm';
import { Box, colors, Typography } from '@material-ui/core';

const View = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [formConfig, setFormConfig] = useState({
    action: '',
    data: { usrName: '', usrPswd: '', usrRole: '' }
  });
  const [triggerProcess, setTriggerProcess] = useState(0);
  const [user, setUser] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const { usrID } = useParams();

  const openModal = (conf) => {
    setOpen(true);
    setFormConfig(conf);
  };

  const handleClose = () => {
    setOpen(false);
    setFormConfig({
      action: '',
      data: { usrName: '', usrPswd: '', usrRole: '' }
    });
  };

  const finishProcess = (newUsrID) => {
    navigate(`/app/users/${newUsrID}`);
    setTriggerProcess(triggerProcess + 1);
  };

  const openMenu = (event) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const addData = () => openModal({ action: 'NEW' });
  const editData = (data) => openModal({ action: 'EDIT', data });
  const lockData = (data) => openModal({ action: 'LOCK', data });
  const unlockData = (data) => openModal({ action: 'UNLOCK', data });

  const goToList = () => navigate('/app/users');

  const getData = async () => {
    try {
      const response = await UserServices.get(usrID);
      setUser(response.data.data);
    } catch (error) {
      toast.error('Tidak dapat memuat data', {
        position: 'top-center',
        autoClose: 3000
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData(usrID);
  }, [usrID, triggerProcess]);

  return isLoading ? (
    'Loading ...'
  ) : (
    <Card>
      {user.usrState === 'X' ? (
        <div
          style={{
            width: '100%',
            backgroundColor: colors.red[400],
            color: 'white',
            textAlign: 'center',
            paddingBlock: 10
          }}
        >
          <Typography>
            TIDAK AKTIF
          </Typography>
        </div>
      ) : (
        ''
      )}

      <CardHeader
        action={(
          <>
            <IconButton onClick={goToList}>
              <ListIcon />
            </IconButton>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={openMenu}
              aria-label="settings"
            >
              <MoreVertIcon />
            </IconButton>
          </>
        )}
        title="USER PROFILE"
      />
      <hr />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem onClick={addData}>
          <AddIcon />
          &nbsp;&nbsp; Tambah Baru
        </MenuItem>
        <MenuItem onClick={() => editData(user)}>
          <EditIcon />
          &nbsp;&nbsp; Edit
        </MenuItem>
        {user.usrState === 'A' ? (
          <MenuItem onClick={() => lockData(user)}>
            <LockIcon />
            &nbsp;&nbsp; Lock
          </MenuItem>
        ) : (
          <MenuItem onClick={() => unlockData(user)}>
            <LockOpenIcon />
            &nbsp;&nbsp; Unlock
          </MenuItem>
        )}
      </Menu>

      <CardContent>
        <Typography variant="h6">User ID:</Typography>
        <Typography>{user.usrID}</Typography>

        <Typography variant="h6" sx={{ mt: 3 }}>User Name:</Typography>
        <Typography>{user.usrName}</Typography>

        <Typography variant="h6" sx={{ mt: 3 }}>
          Roles:
        </Typography>
        <Typography>
          {user.usrRole
            .split('')
            .map((role) => (role === 'A' ? 'Admin' : 'Karyawan'))
            .join(', ')}
        </Typography>

        <Box
          component="img"
          src={user.usrPicPath || '/static/images/user_default.png'}
          sx={{ width: '150px', mt: 3 }}
        />
      </CardContent>

      {/* modal */}
      <Modal open={open} title="User Form" closeModal={handleClose}>
        <UserForm
          formConfig={formConfig}
          closeModal={handleClose}
          finishProcess={finishProcess}
        />
      </Modal>
    </Card>
  );
};

export default View;
