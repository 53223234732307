import API from 'src/utils/API';

const list = (params) => API.get(`/api/activities?params=${params}`);

const get = (kegID) => API.get(`/api/activities/${kegID}`);

const getByUser = (usrID) => API.get(`/api/activities/${usrID}/list-kary`);

const getUserStatus = (usrID) => API.get(`/api/activities/${usrID}/status-act-kary-today`);

const getUserSummary = (usrID) => API.get(`/api/analytic/sum-act-kary/${usrID}`);

const store = (data) => API.post('/api/activities', data);

const update = (id, data) => API.put(`/api/activities/${id}`, data);

const approve = (id, data) => API.put(`api/activities/${id}/approve`, data);

const reject = (id, data) => API.put(`api/activities/${id}/reject`, data);

const destroy = (id) => API.delete(`/users/${id}`);

export default {
  get, getByUser, getUserStatus, getUserSummary, list, store, destroy, approve, reject, update
};
