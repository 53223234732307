import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSetRecoilState } from 'recoil';
import { authCheckState } from 'src/stores/AuthState';
import { useNavigate } from 'react-router';

export default function LogoutDialog(props) {
  const { handleClose, open } = props;
  const setAuth = useSetRecoilState(authCheckState);
  const navigate = useNavigate();
  const handleLogout = () => {
    setAuth(false);
    localStorage.removeItem('userToken');
    navigate('/login');
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure to logout?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            if you click the logout button we will redirect you to login page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            cancel
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
