import { atom } from 'recoil';

export const currentActivityIDState = atom({
  key: 'CurrentActivityID',
  default: null,
});

export const activityTriggerState = atom({
  key: 'ActivityTrigger',
  default: 0,
});
