import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { sign } from 'jsonwebtoken';
import {
  Button,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  TextField,
  Typography,
  Box,
  ImageList,
  ImageListItem,
  Badge
} from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { authUserState } from 'src/stores/AuthState';
import ActivityServices from 'src/services/ActivityServices';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import AddIcon from '@material-ui/icons/Add';
import TodayIcon from '@material-ui/icons/Today';
import { currentActivityState } from 'src/stores/selector';

const validationSchema = yup.object({
  kegDesc: yup.string().required('Catatan harus di isi')
});

const ActivityForm = (props) => {
  const { finishProcess, closeModal } = props;
  const user = useRecoilValue(authUserState);
  const resetCurrentActivity = useResetRecoilState(currentActivityState);
  const activity = useRecoilValue(currentActivityState);
  const [pictures, setPictures] = useState([]);
  const [deletePicIDs, setDeletePicIDs] = useState([]);
  const theme = useTheme();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      kegusrID: user.usrID,
      kegDesc: activity ? activity.kegDesc : '',
      picPath: []
    },
    onSubmit: async (values) => {
      const formData = new FormData();
      const data = sign(
        {
          kegusrID: values.kegusrID,
          kegDesc: values.kegDesc,
          delete_pic: deletePicIDs
        },
        process.env.REACT_APP_SECRET_KEY
      );
      formData.append('data', data);
      Array.from(values.picPath).forEach((pic) => formData.append('picPath', pic));
      try {
        if (activity) {
          await ActivityServices.update(activity.kegID, formData);
          toast('Activity was successfully updated.', {
            position: 'top-center',
            autoClose: 3000
          });
        } else {
          await ActivityServices.store(formData);
          toast('Activity was successfully created.', {
            position: 'top-center',
            autoClose: 3000
          });
        }
      } catch (error) {
        toast.error('Tidak dapat menyimpan data', {
          position: 'top-center',
          autoClose: 3000
        });
      }
      finishProcess();
      resetCurrentActivity();
      closeModal();
      formik.resetForm();
    },
    validationSchema
  });

  const handlePicture = (e) => {
    const file = e.currentTarget.files[0];
    const url = URL.createObjectURL(file);
    setPictures([...pictures, { picPath: url }]);
    formik.setFieldValue('picPath', [...formik.values.picPath, file]);
  };

  const handleDeletePicture = (picture) => {
    const newPictures = pictures.filter((pic) => pic.picPath !== picture.picPath);
    setPictures(newPictures);
    if (picture.picID) setDeletePicIDs([...deletePicIDs, picture.picID]);
  };

  useEffect(() => {
    if (activity) {
      setPictures(activity.pic);
    } else {
      setPictures([]);
    }
  }, [activity]);

  return (
    <div>
      <List dense>
        <ListItem>
          <ListItemIcon>
            <PersonOutlineIcon color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={activity ? activity.kegusrID : user.usrID}
            sx={{ color: theme.palette.primary.main }}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <TodayIcon />
          </ListItemIcon>
          <ListItemText
            secondary={activity ? moment(activity.kegTime).format('LLL') : moment().format('LLL')}
          />
        </ListItem>
      </List>
      <form onSubmit={formik.handleSubmit} noValidate>
        <FormControl sx={{ my: 3 }} fullWidth>
          <TextField
            id="kegDesc"
            name="kegDesc"
            label="Notes"
            rows={4}
            multiline
            value={formik.values.kegDesc}
            onChange={formik.handleChange}
            error={formik.touched.kegDesc && Boolean(formik.errors.kegDesc)}
            helperText={formik.touched.kegDesc && formik.errors.kegDesc}
          />
        </FormControl>

        <Typography variant="subtitle1" color={theme.palette.text.secondary}>
          Pictures
        </Typography>
        <Box>
          <ImageList cols={6} sx={{ pt: '10px' }}>
            {pictures.map((picture) => (
              <ImageListItem key={picture.picPath} sx={{ mr: '10px' }}>
                <Badge badgeContent="X" color="primary" sx={{ cursor: 'pointer' }} onClick={() => handleDeletePicture(picture)}>
                  <img src={picture.picPath} alt={picture.picPath} loading="lazy" style={{ width: '100px', height: '100px', objectFit: 'contain' }} />
                </Badge>
              </ImageListItem>
            ))}

            <input
              style={{ display: 'none' }}
              id="picPath"
              name="picPath"
              type="file"
              onChange={(e) => handlePicture(e)}
            />
            { pictures.length < 4 && (
              <ImageListItem>
                <label htmlFor="picPath">
                  <Button
                    variant="outlined"
                    color="secondary"
                    component="span"
                    sx={{ width: '100px', height: '100px' }}
                  >
                    <AddIcon />
                  </Button>
                </label>
              </ImageListItem>
            )}
          </ImageList>
        </Box>

        <Button variant="contained" type="submit" sx={{ mt: 3 }}>
          {activity ? 'Update Activity' : 'Create Activity'}
        </Button>
      </form>
    </div>
  );
};

export default ActivityForm;
