import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  Hidden,
  TextField,
  Typography
} from '@material-ui/core';
import AuthServices from 'src/services/AuthServices';
import { useSetRecoilState } from 'recoil';
import { authCheckState } from 'src/stores/AuthState';

const validationSchema = yup.object({
  usrName: yup
    .string('Please enter Your name')
    .required('Your name is required'),
  usrPswd: yup.string('Enter your password').required('Password is required')
});

const Login = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const setAuth = useSetRecoilState(authCheckState);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      usrName: '',
      usrPswd: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        const response = await AuthServices.login(values);
        const { data } = response.data;
        await localStorage.setItem('userToken', data.token);
        await setAuth(values);
        if (data.usrRole.includes('A')) {
          navigate('/app/dashboard', { replace: true });
        } else {
          navigate('/app/home', { replace: true });
        }
        toast('🚀 Successfully logged in.');
        setIsSubmitting(false);
      } catch (err) {
        if (err.response.status !== 500) {
          console.log(err);
          toast.error(err.response.data.errors[0].msg, {
            position: 'top-center',
            autoClose: 3000
          });
        } else {
          console.log(err);
        }
        setIsSubmitting(false);
      }
    }
  });

  return (
    <>
      <Helmet>
        <title>Login | Daily Reporting Apps</title>
      </Helmet>
      <Box
        sx={{
          backgroundImage:
            'url("/static/images/bg_lines.png"), linear-gradient(180deg, #4CB1B8 0%, #062224 100%)',
          backgroundPosition: 'right',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          padding: '15px'
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            backgroundColor: '#fff',
            padding: '25px',
            borderRadius: '10px'
          }}
        >
          <Grid container spacing={4}>
            <Hidden smDown>
              <Grid item sm={6}>
                <Box
                  item
                  sx={{
                    backgroundImage:
                      'url("/static/images/logo_activity_reporting.png"), linear-gradient(180deg, #4CB1B8 0%, #062224 100%)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    height: '400px',
                    width: '100%',
                    borderRadius: '10px'
                  }}
                />
              </Grid>
            </Hidden>
            <Grid item sm={6}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  height: '100%'
                }}
              >
                <form onSubmit={formik.handleSubmit}>
                  <Box sx={{ mb: 3 }}>
                    <Typography color="textPrimary" variant="h2">
                      Sign in
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Sign in on the internal platform
                    </Typography>
                  </Box>
                  <FormControl fullWidth sx={{ my: 1 }}>
                    <TextField
                      id="usrName"
                      name="usrName"
                      label="Your name"
                      variant="standard"
                      value={formik.values.usrName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.usrName && Boolean(formik.errors.usrName)
                      }
                      helperText={
                        formik.touched.usrName && formik.errors.usrName
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ my: 1 }}>
                    <TextField
                      id="usrPswd"
                      name="usrPswd"
                      label="Your password"
                      type="password"
                      variant="standard"
                      value={formik.values.usrPswd}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.usrPswd && Boolean(formik.errors.usrPswd)
                      }
                      helperText={
                        formik.touched.usrPswd && formik.errors.usrPswd
                      }
                    />
                  </FormControl>
                  <Box sx={{ pt: 2 }}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Sign in now
                    </Button>
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box sx={{ mx: 'auto', mt: 5 }}>
          <img
            src="/static/images/power_by_seucom.png"
            width="180px"
            alt="power by seucom"
          />
        </Box>
      </Box>
    </>
  );
};

export default Login;
