/* eslint-disable */

import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import SyncIcon from '@material-ui/icons/Sync';
import AddIcon from '@material-ui/icons/Add';
import UserServices from 'src/services/UserServices';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import VisibilityIcon from '@material-ui/icons/Visibility';

const UserList = (props) => {
  const { openModal, triggerProcess } = props;
  const navigate = useNavigate();
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [queryParams, setQueryParams] = useState({
    limit: 100,
    dataFilter: { usrState: { filter: 'A' } },
    dataSort: []
  });

  // untuk konfigurasi kolom default
  const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilterComponentParams: { suppressFilterButton: true },
    floatingFilter: true,
    suppressMenu: true,
    filterParams: { apply: false, newRowsAction: 'keep', buttons: ['apply'] }
  };

  // grid options
  const gridOptions = {
    onFilterChanged: (params) => {
      const dataFilter = params.api.getFilterModel();
      setQueryParams({ ...queryParams, dataFilter });
    },

    onSortChanged: (params) => {
      const dataSort = params.api.getSortModel();
      setQueryParams({ ...queryParams, dataSort });
    }
  };

  // saat grid ready, set data awal
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  // set data filter
  const setDataFilter = () => {
    const { dataFilter } = queryParams;

    Object.entries(dataFilter).forEach((e) => {
      const filterInstance = gridApi.getFilterInstance(e[0]);
      const model = {
        type: 'contains',
        filter: dataFilter[e[0]].filter
      };
      filterInstance.setModel(model);
    });
    // gridApi.onFilterChanged();
  };

  const actionButton = (cell) => {
    const { data } = cell;
    return (
      <>
        <div style={{ marginLeft: -10 }}>
          {(data.usrState === 'A')
            ? (
              <IconButton onClick={() => openModal({ data, action: 'LOCK' })} color="inherit" style={{ padding: 3 }}>
                <LockIcon fontSize="small" />
              </IconButton>
            )
            : ''}
          {(data.usrState === 'X')
            ? (
              <IconButton onClick={() => openModal({ data, action: 'UNLOCK' })} color="inherit" style={{ padding: 3 }}>
                <LockOpenIcon fontSize="small" />
              </IconButton>
            )
            : ''}
          <IconButton onClick={() => openModal({ data, action: 'EDIT' })} color="inherit" style={{ padding: 3 }}>
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={() => navigate(`/app/users/${data.usrID}`)} color="inherit" style={{ padding: 3 }}>
            <VisibilityIcon fontSize="small" />
          </IconButton>
        </div>
      </>
    );
  };

  const fetchData = async () => {
    try {
      const strParams = JSON.stringify(queryParams);
      const response = await UserServices.list(strParams);
      setRowData(response.data.data);
    } catch (error) {
      toast.error('Tidak dapat memuat data', {
        position: 'top-center',
        autoClose: 3000
      });
    }
  };

  // update data saat limit berubah
  const onLimitChanged = (e) => {
    setQueryParams({ ...queryParams, limit: e.target.value });
  };

  // set default filter dari queryParams
  useEffect(() => {
    if (gridApi) {
      setDataFilter();
    }
  }, [gridApi]);

  useEffect(() => {
    fetchData();
  }, [queryParams, triggerProcess]);

  const usrStateFilter = forwardRef((_props, ref) => {
    const inputRef = useRef(null);

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
      return {
        onParentModelChanged(parentModel) {
          if (!parentModel) {
            inputRef.current.value = '';
          } else {
            inputRef.current.value = parentModel.filter + '';
          }
        },
      };
    });
  
    const onSelectOptionChanged = (input) => {  
      _props.parentFilterInstance((instance) => {
        const gridApi = instance.textFilterParams.api;
        const filterInstance = gridApi.getFilterInstance('usrState');
        const model = {
          type: 'contains',
          filter: input.target.value
        };
        filterInstance.setModel(model);
        gridApi.onFilterChanged();
      });
    };
  
    return (
      <>
        <select
          className="select-box-floating-filter"
          ref={inputRef}
          onInput={onSelectOptionChanged}
        >
          <option value="A">Aktif</option>
          <option value="X">Tidak Aktif</option>
          <option value="">ALL</option>
        </select>
      </>
    );
  });


  const usrRoleFilter = forwardRef((_props, ref) => {
    const inputRef = useRef(null);

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
      return {
        onParentModelChanged(parentModel) {
          if (!parentModel) {
            inputRef.current.value = '';
          } else {
            inputRef.current.value = parentModel.filter + '';
          }
        },
      };
    });
  
    const onSelectOptionChanged = (input) => {  
      _props.parentFilterInstance((instance) => {
        const gridApi = instance.textFilterParams.api;
        const filterInstance = gridApi.getFilterInstance('usrRole');
        const model = {
          type: 'contains',
          filter: input.target.value
        };
        filterInstance.setModel(model);
        gridApi.onFilterChanged();
      });
    };
  
    return (
      <>
        <select
          className="select-box-floating-filter"
          ref={inputRef}
          onInput={onSelectOptionChanged}
          defaultValue=""
        >
          <option value="A">Admin</option>
          <option value="K">Karyawan</option>
          <option value="AK">Admin | Karyawan</option>
          <option value="">ALL</option>
        </select>
      </>
    );
  });

  return (
    <div className="ag-theme-alpine" style={{ height: 650 }}>
      <div style={{ width: '100%' }}>
        <h2 style={{ float: 'left', margin: 0 }}>User</h2>

        <AddIcon
          onClick={() => openModal({ data: null, action: 'NEW' })}
          sx={{
            float: 'right',
            backgroundColor: '#5664D2',
            color: 'white',
            marginLeft: 5,
            width: 30,
            height: 30,
            cursor: 'pointer'
          }}
        />

        {/* select option limit data */}
        <select
          value={queryParams.limit}
          onChange={onLimitChanged}
          style={{
            float: 'right',
            border: 0,
            backgroundColor: 'white',
            height: 32,
            marginLeft: 5,
            marginTop: -5
          }}
        >
          <option value="100">Limit: 100</option>
          <option value="500">Limit: 500</option>
          <option value="1000">Limit: 1000</option>
          <option value="ALL">Limit: ALL</option>
        </select>

        <SyncIcon
          onClick={setRowData}
          sx={{ float: 'right', padding: 0, cursor: 'pointer' }}
        />

        <div style={{ clear: 'both' }} />
      </div>

      {/* AG Grid */}
      <AgGridReact
        onGridReady={onGridReady}
        gridOptions={gridOptions}
        defaultColDef={defaultColDef}
        rowData={rowData}
        frameworkComponents={{ actionButton, usrStateFilter, usrRoleFilter }}
        rowSelection="single"
      >
        {/* definisi kolom */}
        <AgGridColumn
          headerName="Action"
          sortable={false}
          width={85}
          cellStyle={{ textAlign: 'center' }}
          filter={false}
          cellRenderer="actionButton"
        />
        <AgGridColumn
          field="usrID"
          headerName="User ID"
          width={100}
          cellStyle={{ textAlign: 'center' }}
        />
        <AgGridColumn field="usrName" headerName="Name" />
        <AgGridColumn
          field="usrRole"
          headerName="Role"
          width={170}
          floatingFilterComponent="usrRoleFilter"
          cellStyle={{ textAlign: 'center' }}
          cellRenderer={(params) => {
            const { data } = params;
            return (data.usrRole === 'A') ? 'Admin' : (data.usrRole === 'K') ? 'Karyawan' : 'Admin | Karyawan';
          }}
        />
        <AgGridColumn
          field="usrState"
          headerName="Status"
          width={120}
          floatingFilterComponent="usrStateFilter"
          cellStyle={{ textAlign: 'center' }}
          cellRenderer={(params) => {
            const { data } = params;
            return (data.usrState === 'A') ? 'Aktif' : 'Tidak Aktif';
          }}
        />
      </AgGridReact>
    </div>
  );
};

export default UserList;
