import { toast } from 'react-toastify';
import { atom, DefaultValue, selector } from 'recoil';
import ActivityServices from 'src/services/ActivityServices';
import UserServices from 'src/services/UserServices';
import { activityTriggerState, currentActivityIDState } from 'src/stores/atom';

export const asyncDefault = selector({
  key: 'asyncDefault',
  async get() {
    const { data } = await UserServices.getAll();
    return data.data;
  }
});

export const filterUsers = atom({
  key: 'stateWithAsyncDefault',
  default: asyncDefault,
});

export const currentActivityState = selector({
  key: 'CurrentActivity',
  get: async ({ get }) => {
    get(activityTriggerState);
    const id = get(currentActivityIDState);
    if (id) {
      try {
        const response = await ActivityServices.get(id);
        return response.data.data;
      } catch (error) {
        toast.error('Failed to load activity data', {
          position: 'top-center',
          autoClose: 3000
        });
      }
    }
    return null;
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(activityTriggerState, (v) => v + 1);
    }
  }
});
