import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Grid,
  Hidden,
  IconButton,
  Toolbar
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from './Logo';

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => (
  <AppBar
    elevation={0}
    sx={{ backgroundColor: 'background.green', overflow: 'hidden' }}
    {...rest}
  >
    <Grid
      container
      sx={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <Grid item>
        <img src="/static/images/ribbon.png" alt="powered by seucom" />
      </Grid>
      <Grid item>
        <img src="/static/images/ribbon.png" alt="powered by seucom" />
      </Grid>
    </Grid>
    <Toolbar>
      <Box sx={{ flexGrow: 1 }} />
      <RouterLink to="/">
        <Logo style={{ height: '40px' }} />
      </RouterLink>
      <Box sx={{ flexGrow: 1 }} />
      <Hidden lgUp>
        <IconButton color="inherit" onClick={onMobileNavOpen}>
          <MenuIcon />
        </IconButton>
      </Hidden>
    </Toolbar>
  </AppBar>
);

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
