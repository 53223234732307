import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
} from '@material-ui/core';
import {
  Home as HomeIcon,
  BarChart as BarChartIcon,
  Activity as ActivityIcon,
  // Printer as PrinterIcon,
  Users as UsersIcon,
} from 'react-feather';
import InputIcon from '@material-ui/icons/Input';
import { useRecoilValue } from 'recoil';
import { authUserState } from 'src/stores/AuthState';
import NavItem from './NavItem';
import UserDetail from './sidebar/UserDetail';
import LogoutDialog from './LogoutDialog';

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard',
    role: 'A'
  },
  {
    href: '/app/home',
    icon: HomeIcon,
    title: 'Daily Activity',
    role: 'K'
  },
  {
    href: '/app/users',
    icon: UsersIcon,
    title: 'Users',
    role: 'A'
  },
  {
    href: '/app/activities',
    icon: ActivityIcon,
    title: 'Activity',
    role: 'A'
  },
  // {
  //   href: '/app/report',
  //   icon: PrinterIcon,
  //   title: 'Report',
  //   role: 'A'
  // }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const user = useRecoilValue(authUserState);
  const [open, setOpen] = useState(false);
  const openLogoutDialog = () => setOpen(true);
  const closeLogoutDialog = () => setOpen(false);

  const contains = (target, patternText) => {
    let value = 0;
    const pattern = patternText.split('');
    pattern.forEach((word) => {
      value += target.includes(word);
    });
    return (value > 0);
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <UserDetail />
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              sx={{
                display: contains(item.role, user.usrRole) ? 'block' : 'none',
                backgroundColor: location.pathname.includes(item.href) ? '#EECF2A' : '',
              }}
            />
          ))}
          <NavItem
            href="#"
            title="Logout"
            icon={InputIcon}
            onClick={openLogoutDialog}
          />
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          backgroundColor: 'background.green',
          textAlign: 'center',
          py: 1
        }}
      >
        <img src="/static/images/power_by_seucom.png" alt="powered by seucom" width="180px" />
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <LogoutDialog open={open} handleClose={closeLogoutDialog} />
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default DashboardSidebar;
