import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  Avatar,
  Box,
  colors,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@material-ui/core';
import {
  AccessTime as AccessTimeIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Edit as EditIcon
} from '@material-ui/icons';
import { authUserState } from 'src/stores/AuthState';
import ActivityServices from 'src/services/ActivityServices';
import { useNavigate } from 'react-router';
import { currentActivityIDState } from 'src/stores/atom';

const ActivityHistorys = (props) => {
  const { triggerProcess, openModal } = props;
  const navigate = useNavigate();
  const user = useRecoilValue(authUserState);
  const [activityHistory, setActivityHistory] = useState([]);
  const setCurrentActivityID = useSetRecoilState(currentActivityIDState);

  const handleOpenModal = (kegID) => {
    setCurrentActivityID(kegID);
    openModal({ action: 'UPDATE' });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ActivityServices.getByUser(user.usrID);
        setActivityHistory(response.data.data);
      } catch (error) {
        toast.error('Tidak dapat memuat data', {
          position: 'top-center',
          autoClose: 3000
        });
      }
    };
    fetchData();
  }, [triggerProcess]);

  return (
    <Box sx={{ backgroundColor: 'white', p: '20px' }}>
      <Typography variant="h2">Activity History</Typography>
      <Divider sx={{ my: 2 }} />

      <List sx={{ height: 300, overflowY: 'scroll' }}>
        {activityHistory.map((activity) => (
          <ListItem
            key={activity.kegID}
            sx={{ boxShadow: 3, mb: 3 }}
            secondaryAction={(
              <>
                <IconButton
                  onClick={() => handleOpenModal(activity.kegID)}
                  edge="end"
                  sx={{ display: activity.kegState === '' ? 'inline' : 'none' }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton edge="end" onClick={() => navigate(`/app/activities/${activity.kegID}`)}>
                  <RemoveRedEyeIcon />
                </IconButton>
              </>
            )}
          >
            <ListItemAvatar>
              <Avatar
                sx={{
                  bgcolor: {
                    '': colors.grey[500],
                    A: colors.green[500],
                    X: colors.red[500]
                  }[activity.kegState]
                }}
              >
                <AccessTimeIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={moment(activity.kegTime).format('LL')}
              secondary={
                {
                  '': 'Waiting For Approval',
                  A: 'Approved',
                  X: 'Rejected'
                }[activity.kegState]
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ActivityHistorys;
