import moment from 'moment';
import { Line } from 'react-chartjs-2';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import AnalyticService from 'src/services/AnalyticService';

const Sales = (props) => {
  const theme = useTheme();
  const [totalActRptPerHari, setTotalActRptPerHari] = useState([]);
  const [dataLabels, setDataLabels] = useState([]);

  const data = {
    datasets: [
      {
        borderColor: colors.green[300],
        data: totalActRptPerHari,
        label: 'Total Activity Reports',
        fill: false
      }
    ],
    labels: dataLabels
  };

  const options = {
    animation: false,
    cornerRadius: 10,
    layout: { padding: 0 },
    legend: { display: true },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            stepSize: 1,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const fetchData = async () => {
    try {
      const response = await AnalyticService.sumActLastMonth();
      const dataChartLine = response.data.data;
      const actRptPerHari = dataChartLine.map((obj) => obj.totalActRptPerHari);
      const tglActRpt = dataChartLine.map((obj) => moment(obj.tglhari_act_rpt).format('DD MMMM'));
      setTotalActRptPerHari(actRptPerHari);
      setDataLabels(tglActRpt);
    } catch (error) {
      console.log('Tidak dapat memuat data');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card {...props}>
      <CardHeader
        title="Activity Reports: This Month"
      />
      <Divider />
      <CardContent sx={{ height: '85%' }}>
        <Line
          data={data}
          options={options}
        />
      </CardContent>
    </Card>
  );
};

export default Sales;
