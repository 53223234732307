import API from 'src/utils/API';

const list = (params) => API.get(`/api/users?params=${params}`);

const get = (id) => API.get(`/api/users/${id}`);

const create = (data) => API.post('/api/users', data);

const edit = (id, data) => API.put(`/api/users/${id}`, data);

const lock = (id) => API.patch(`/api/users/${id}/lock`);

const unlock = (id) => API.patch(`/api/users/${id}/unlock`);

export default {
  list, get, create, lock, unlock, edit
};
