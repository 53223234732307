import React, { useEffect, useState } from 'react';
import { sign } from 'jsonwebtoken';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import UserServices from 'src/services/UserServices';

const validationSchema = yup.object({
  usrName: yup.string(),
  usrPswd: yup.string(),
  usrRole: yup.string()
});

const UserForm = (props) => {
  const { closeModal, formConfig, finishProcess } = props;
  const [isSubmit, setIsSubmit] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [imagePreview, setImagePreview] = useState(
    '/static/images/user_default.png'
  );

  const initialUser = {
    usrName: '',
    usrPswd: '',
    usrRole: '',
    usrPicPath: null
  };

  const [user, setUser] = useState({ ...initialUser });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: user,
    onSubmit: async (values) => {
      setIsSubmit(true);
      try {
        let { usrID } = user;
        let msgSukses = '';
        const formData = new FormData();
        const data = sign(
          {
            usrID: values.usrID,
            usrName: values.usrName,
            usrRole: values.usrRole,
            usrPswd: values.usrPswd
          },
          process.env.REACT_APP_SECRET_KEY
        );
        formData.append('data', data);
        formData.append('usrPicPath', values.usrPicPath);

        if (formConfig.action === 'NEW') {
          const result = await UserServices.create(formData);
          usrID = result.data.data.usrID;
          msgSukses = 'Sukses simpan data';
        } else if (formConfig.action === 'EDIT') {
          await UserServices.edit(usrID, formData);
          msgSukses = 'Sukses update data';
        } else if (formConfig.action === 'LOCK') {
          await UserServices.lock(usrID);
          msgSukses = 'Sukses lock data';
        } else if (formConfig.action === 'UNLOCK') {
          await UserServices.unlock(usrID);
          msgSukses = 'Sukses unlock data';
        }
        finishProcess(usrID);
        closeModal();
        toast(msgSukses, {
          position: 'top-center',
          autoClose: 3000
        });
      } catch (error) {
        if (error.response.status !== 500) {
          const { errors } = error.response.data;
          let msgErr = '';

          errors.forEach((err) => {
            msgErr += `${err.msg}, `;
          });

          msgErr = msgErr.slice(0, -2);

          toast.error(`${msgErr}`, {
            position: 'top-center',
            autoClose: 3000
          });
        }
      }
      setIsSubmit(false);
    },
    validationSchema
  });

  const handlePhoto = (e) => {
    const file = e.currentTarget.files[0];
    const url = URL.createObjectURL(file);
    setImagePreview(url);
    formik.setFieldValue('usrPicPath', file);
  };

  useEffect(() => {
    if (formConfig.action !== 'NEW') {
      formConfig.data.usrPswd = '';
      setUser(formConfig.data);
      setImagePreview(formConfig.data.usrPicPath);
    } else {
      formik.resetForm({ ...initialUser });
    }

    if (formConfig.action === 'LOCK') {
      setConfirmationMessage('Klik OK untuk lock data');
    } else if (formConfig.action === 'UNLOCK') {
      setConfirmationMessage('Klik OK untuk unlock data');
    }

    return () => setUser({ ...initialUser });
  }, [formConfig]);

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      {formConfig.action === 'EDIT' || formConfig.action === 'NEW' ? (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '25%',
              mx: 'auto'
            }}
          >
            <img
              src={imagePreview || '/static/images/user_default.png'}
              alt="user preview"
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'contain',
              }}
            />
            <input
              style={{ display: 'none' }}
              id="usrPicPath"
              name="usrPicPath"
              type="file"
              onChange={(e) => handlePhoto(e)}
            />
            <label htmlFor="usrPicPath">
              <Button
                variant="text"
                size="small"
                color="primary"
                component="span"
                sx={{ mt: 0 }}
              >
                Upload
              </Button>
            </label>
          </Box>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <TextField
              required
              id="usrName"
              name="usrName"
              label="User Name"
              variant="standard"
              value={formik.values.usrName}
              onChange={formik.handleChange}
              error={formik.touched.usrName && Boolean(formik.errors.usrName)}
              helperText={formik.touched.usrName && formik.errors.usrName}
            />
          </FormControl>
          <FormControl
            required
            component="fieldset"
            sx={{ mt: 4 }}
            error={formik.touched.usrRole && Boolean(formik.errors.usrRole)}
            fullWidth
          >
            <FormLabel component="legend">User Role</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                id="usrRole"
                label="Admin"
                name="usrRole"
                value="A"
                checked={formik.values.usrRole.includes('A')}
                onChange={(e) => {
                  if (!e.target.checked) {
                    formik.setFieldValue(
                      'usrRole',
                      formik.values.usrRole.replace('A', '')
                    );
                  } else {
                    formik.setFieldValue(
                      'usrRole',
                      `${formik.values.usrRole}A`
                    );
                  }
                }}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Karyawan"
                id="usrRole"
                name="usrRole"
                value="K"
                checked={formik.values.usrRole.includes('K')}
                onChange={(e) => {
                  if (!e.target.checked) {
                    formik.setFieldValue(
                      'usrRole',
                      formik.values.usrRole.replace('K', '')
                    );
                  } else {
                    formik.setFieldValue(
                      'usrRole',
                      `${formik.values.usrRole}K`
                    );
                  }
                }}
              />
            </FormGroup>
            <FormHelperText>
              {formik.touched.usrRole && formik.errors.usrRole}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth sx={{ my: 1 }}>
            <TextField
              id="usrPswd"
              name="usrPswd"
              label="Password"
              type="password"
              variant="standard"
              value={formik.values.usrPswd}
              onChange={formik.handleChange}
              error={formik.touched.usrPswd && Boolean(formik.errors.usrPswd)}
              helperText={formik.touched.usrPswd && formik.errors.usrPswd}
            />
          </FormControl>
        </>
      ) : (
        <Typography>{confirmationMessage}</Typography>
      )}
      <FormControl fullWidth sx={{ mt: 3 }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              onClick={closeModal}
              style={{ float: 'left', width: 110 }}
              variant="text"
              startIcon={<ClearIcon />}
            >
              CANCEL
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              disabled={isSubmit}
              style={{ float: 'right', width: 110 }}
              variant="contained"
              startIcon={<CheckIcon />}
            >
              OK
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </form>
  );
};

export default UserForm;
