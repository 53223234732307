import * as React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MUIModal from '@material-ui/core/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxHeight: '90vh',
  maxWidth: '98vw',
  overflowY: 'scroll',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4
};

export default function Modal(props) {
  const {
    children, closeModal, open, title
  } = props;

  return (
    <div>
      <MUIModal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            {title}
          </Typography>
          <Box sx={{ mt: 2 }}>
            {children}
          </Box>
        </Box>
      </MUIModal>
    </div>
  );
}
