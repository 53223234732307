import Axios from 'axios';

const api = Axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }
});

api.interceptors.request.use(
  (config) => {
    const conf = config;
    if (localStorage.getItem('userToken')) {
      conf.headers.Authorization = `Token ${localStorage.getItem('userToken')}`;
    }
    return conf;
  },
  (error) => Promise.reject(error)
);

export default api;
