import React from 'react';
import { Avatar, Box, Typography } from '@material-ui/core';

import { useRecoilValue } from 'recoil';
import { authUserState } from 'src/stores/AuthState';

const UserDetail = () => {
  const user = useRecoilValue(authUserState);

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        p: 2
      }}
    >
      <Avatar
        src={user.usrPicPath || '/static/images/user_default.png'}
        sx={{
          width: 64,
          height: 64,
          border: '2px solid #24ede6'
        }}
      />
      <Box sx={{ pl: 2 }}>
        <Typography color="textPrimary" variant="h5">
          {user.usrName}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.usrRole.split('').map((role) => (role === 'A' ? 'Admin' : 'Karyawan')).join(' | ')}
        </Typography>
      </Box>
    </Box>
  );
};

export default UserDetail;
