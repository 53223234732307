import PropTypes from 'prop-types';
import {
  Avatar, Card, CardContent, Grid, Typography
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';

const ActivityApproved = (props) => {
  const { total } = props;

  return (
    <Card sx={{ height: '100%' }} {...props}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h5">
              Activity Approved
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {`${total} Activity`}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: green[600],
                height: 56,
                width: 56
              }}
            >
              <CheckIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ActivityApproved.propTypes = {
  total: PropTypes.number,
};

export default ActivityApproved;
