import API from 'src/utils/API';

const sumActToday = () => API.get('/api/analytic/sum-act-today');

const sumActLast7Today = () => API.get('/api/analytic/sum-act-last7days');

const sumActLastMonth = () => API.get('/api/analytic/sum-act-lastmonth');

export default {
  sumActToday, sumActLast7Today, sumActLastMonth
};
