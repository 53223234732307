import React, { useEffect, useState, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Card,
  Button,
  Divider,
  Grid,
  Typography,
  CardContent,
  Avatar
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ActivityHistorys from 'src/components/home/ActivityHistorys';
import ActivityServices from 'src/services/ActivityServices';
import { authUserState } from 'src/stores/AuthState';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import ActivityForm from 'src/components/home/ActivityForm';
import Modal from 'src/components/Modal';

const Home = () => {
  const user = useRecoilValue(authUserState);
  const [triggerProcess, setTriggerProcess] = useState(0);
  const [formConfig, setFormConfig] = useState({ action: '' });
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('');
  const [summary, setSummary] = useState({});

  const finishProcess = () => setTriggerProcess(triggerProcess + 1);

  const openModal = (conf) => {
    setFormConfig(conf);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const getStatus = async () => {
    try {
      const response = await ActivityServices.getUserStatus(user.usrID);
      const { data } = response.data;
      setStatus(data.hasAlreadyMadeActRpt);
    } catch (error) {
      toast.error('Tidak dapat memuat data', {
        position: 'top-center',
        autoClose: 3000
      });
    }
  };

  const getSummary = async () => {
    try {
      const response = await ActivityServices.getUserSummary(user.usrID);
      const { data } = response.data;
      setSummary(data);
    } catch (error) {
      toast.error('Tidak dapat memuat data', {
        position: 'top-center',
        autoClose: 3000
      });
    }
  };

  useEffect(() => {
    getStatus();
    getSummary();
  }, [triggerProcess]);

  return (
    <>
      <Helmet>
        <title>Home | DSI Daily Reporting App</title>
      </Helmet>

      <Box sx={{ backgroundColor: 'white', p: '20px' }}>
        <Typography variant="h2">Your Activity Reports</Typography>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} xl={3}>
            <Card
              sx={{
                background: 'linear-gradient(180deg, #48D7E0 0%, #2B7479 100%)',
                height: '100%'
              }}
            >
              <CardContent>
                <Grid
                  container
                  spacing={3}
                  sx={{ justifyContent: 'space-between' }}
                >
                  <Grid item>
                    <Typography color="white" gutterBottom variant="h5">
                      Activity Approved
                    </Typography>
                    <Typography color="white" variant="h3">
                      {summary.totalActRptKaryApproved}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Avatar
                      sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        height: 56,
                        width: 56
                      }}
                    >
                      <CheckIcon />
                    </Avatar>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={3}>
            <Card
              sx={{
                background: 'linear-gradient(180deg, #FF91A4 0%, #2B7479 100%)',
                height: '100%'
              }}
            >
              <CardContent>
                <Grid
                  container
                  spacing={3}
                  sx={{ justifyContent: 'space-between' }}
                >
                  <Grid item>
                    <Typography color="white" gutterBottom variant="h5">
                      Activity Rejected
                    </Typography>
                    <Typography color="white" variant="h3">
                      {summary.totalActRptKaryRejected}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Avatar
                      sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        height: 56,
                        width: 56
                      }}
                    >
                      <CloseIcon />
                    </Avatar>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4} xl={3}>
            <Card
              sx={{
                background: 'linear-gradient(180deg, #EECF2A 0%, #2B7479 100%)',
                height: '100%'
              }}
            >
              <CardContent>
                <Grid
                  container
                  spacing={3}
                  sx={{ justifyContent: 'space-between' }}
                >
                  <Grid item>
                    <Typography color="white" gutterBottom variant="h5">
                      Waiting for response
                    </Typography>
                    <Typography color="white" variant="h3">
                      {summary.totalActRptKaryWaiting}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Avatar
                      sx={{
                        backgroundColor: 'white',
                        color: 'black',
                        height: 56,
                        width: 56
                      }}
                    >
                      <AccessTimeIcon />
                    </Avatar>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              padding: '40px 30px 50px 30px',
              backgroundColor: 'background.green',
              backgroundImage: 'url("/static/images/bg_lines.png")',
              textAlign: 'center'
            }}
          >
            <img src="/static/images/logo.png" alt="logo" />
            <Typography
              sx={{
                backgroundColor: 'white',
                p: '15px',
                borderRadius: '5px',
                fontFamily: 'revert',
                fontSize: '24px'
              }}
            >
              {status === 'N'
                ? `Hi ${user.usrName}.., Please remember to write your today activity report :)`
                : `Hi ${user.usrName}.., Thank you for inputing your today activity :)`}
            </Typography>
            {status === 'N' && (
              <Button
                onClick={() => openModal({ action: 'CREATE' })}
                variant="contained"
                sx={{ mt: 3, mx: 'auto' }}
              >
                Create your daily activity
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ActivityHistorys
            triggerProcess={triggerProcess}
            openModal={openModal}
          />
        </Grid>
      </Grid>

      {/* modal */}
      <Modal open={open} title="Activity Form" closeModal={closeModal}>
        <Suspense fallback="loading">
          <ActivityForm
            finishProcess={finishProcess}
            formConfig={formConfig}
            closeModal={closeModal}
          />
        </Suspense>
      </Modal>
    </>
  );
};

export default Home;
