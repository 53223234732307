import { atom, selector } from 'recoil';
import AuthServices from 'src/services/AuthServices';

export const authCheckState = atom({
  key: 'authCheckState',
  default: false,
});

export const authUserState = selector({
  key: 'authUserState',
  get: async ({ get }) => {
    get(authCheckState);
    try {
      const { data } = await AuthServices.me();
      return data.data;
    } catch (error) {
      console.log(error.message);
      return false;
    }
  }
});
