import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Box,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  colors
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Lock as LockIcon,
  Person as PersonIcon,
  CalendarToday as CalendarTodayIcon,
  LockOpen as LockOpenIcon,
  List as ListIcon,
  MoreVert as MoreVertIcon
} from '@material-ui/icons';
import Modal from 'src/components/Modal';
import ActivityServices from 'src/services/ActivityServices';
import ActivityForm from 'src/components/activity/ActivityForm';

import moment from 'moment';
import { useRecoilValue } from 'recoil';
import { authUserState } from 'src/stores/AuthState';

const View = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(authUserState);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openPhotoModal, setOpenPhotoModal] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState('');
  const [formConfig, setFormConfig] = useState({
    action: '',
    data: { usrName: '', usrPswd: '', usrRole: '' }
  });
  const [triggerProcess, setTriggerProcess] = useState(0);
  const [activity, setActivity] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const { kegID } = useParams();

  const handleOpenPhotoModal = (val) => {
    setCurrentPhoto(val);
    setOpenPhotoModal(true);
  };

  const handleClosePhotoModal = () => setOpenPhotoModal(false);

  const openModal = (conf) => {
    setOpen(true);
    setFormConfig(conf);
  };

  const handleClose = () => {
    setOpen(false);
    setFormConfig({
      action: '',
      data: { usrName: '', usrPswd: '', usrRole: '' }
    });
  };

  const finishProcess = () => setTriggerProcess(triggerProcess + 1);

  const openMenu = (event) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const approve = (data) => openModal({ action: 'APPROVE', data });
  const reject = (data) => openModal({ action: 'REJECT', data });

  const goToList = () => navigate('/app/activities');

  const getData = async () => {
    try {
      const response = await ActivityServices.get(kegID);
      setActivity(response.data.data);
    } catch (error) {
      toast.error('Tidak dapat memuat data', {
        position: 'top-center',
        autoClose: 3000
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData(kegID);
  }, [kegID, triggerProcess]);

  return isLoading ? (
    'Loading ...'
  ) : (
    <Card>
      {activity.usrState === 'X' ? (
        <div
          style={{
            width: '100%',
            backgroundColor: colors.red[400],
            color: 'white',
            textAlign: 'center',
            paddingBlock: 10
          }}
        >
          TIDAK AKTIF
        </div>
      ) : (
        ''
      )}

      <CardHeader
        action={user.usrRole.includes('A') && (
          <>
            <IconButton onClick={goToList}>
              <ListIcon />
            </IconButton>
            {activity.kegState === '' && (
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={openMenu}
                aria-label="settings"
              >
                <MoreVertIcon />
              </IconButton>
            )}
          </>
        )}
        title="DETAIL ACTIVITY REPORT"
      />
      <hr />
      {activity.kegState === '' && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}
        >
          <MenuItem onClick={() => approve(activity)}>
            <LockIcon />
            &nbsp;&nbsp; Approve
          </MenuItem>
          <MenuItem onClick={() => reject(activity)}>
            <LockOpenIcon />
            &nbsp;&nbsp; Reject
          </MenuItem>
        </Menu>
      )}

      <CardContent>
        <List>
          <ListItem sx={{ p: 0 }}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={activity.kegusrID} />
          </ListItem>
          <ListItem sx={{ p: 0 }}>
            <ListItemIcon>
              <CalendarTodayIcon />
            </ListItemIcon>
            <ListItemText secondary={moment(activity.kegTime).format('LLL')} />
          </ListItem>
        </List>
        <Typography variant="h6" sx={{ mt: 3 }}>
          Description :
        </Typography>
        <Typography>{activity.kegDesc}</Typography>
        <Typography variant="h6" sx={{ mt: 3 }}>
          Activity Status :
        </Typography>
        <Typography>
          {
            {
              '': ' Waiting For Approval',
              A: ` Approved by ${activity.kegStusrID} on ${moment(
                activity.kegStTime
              ).format('LLL')}`,
              X: ` Rejected by ${activity.kegStusrID} on ${moment(
                activity.kegStTime
              ).format('LLL')}`
            }[activity.kegState]
          }
        </Typography>
        <Box sx={{ display: activity.kegState ? 'block' : 'none' }}>
          <Typography variant="h6" sx={{ mt: 3 }}>
            Approval / Rejected notes :
          </Typography>
          <Typography>{activity.kegStNotes || '-'}</Typography>
        </Box>
        <Typography variant="h6" sx={{ mt: 3 }}>
          Image :
        </Typography>
        <Box>
          <ImageList cols={6}>
            {activity.pic.map((pic) => (
              <>
                <ImageListItem key={pic.picID} onClick={() => handleOpenPhotoModal(pic.picPath)}>
                  <img src={pic.picPath} alt={pic.picID} loading="lazy" style={{ width: '150px', height: '150px', objectFit: 'contain' }} />
                </ImageListItem>
              </>
            ))}
          </ImageList>
        </Box>
      </CardContent>

      {/* modal */}
      <Modal open={open} title="User Form" closeModal={handleClose}>
        <ActivityForm
          formConfig={formConfig}
          closeModal={handleClose}
          finishProcess={finishProcess}
        />
      </Modal>
      <Modal open={openPhotoModal} closeModal={handleClosePhotoModal} title={<CloseIcon onClick={handleClosePhotoModal} sx={{ float: 'right', mb: 1 }} />}>
        <Box
          component="img"
          src={currentPhoto}
          sx={{ width: '100%' }}
        />
      </Modal>
    </Card>
  );
};

export default View;
