import API from 'src/utils/API';
import Encrypt from 'src/utils/Encrypt';

const login = (data) => API.post('/api/auth/login', Encrypt(data));

const me = () => {
  const config = {
    headers: {
      Authorization: `Token ${localStorage.getItem('userToken')}`
    }
  };

  return API.get('/api/auth/verify', config);
};

export default { login, me };
