import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { RecoilRoot } from 'recoil';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import routes from 'src/routes';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'font-awesome/css/font-awesome.min.css';
import 'src/mixins/chartjs';
import 'src/assets/css/seucom.css';

const App = () => {
  const routing = useRoutes(routes);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <RecoilRoot>
        <Suspense fallback="loading...">
          {routing}
        </Suspense>
      </RecoilRoot>
    </ThemeProvider>
  );
};

export default App;
