import { Helmet } from 'react-helmet';
import { Grid } from '@material-ui/core';
import ChartLineActivity from 'src/components/dashboard/ChartLineActivity';
import ChartBarActivity from 'src/components/dashboard/ChartBarActivity';
import ActivityApproved from 'src/components/dashboard/ActivityApproved';
import ActivityWaiting from 'src/components/dashboard/ActivityWaiting';
import ActivityRejected from 'src/components/dashboard/ActivityRejected';
import StaffNotReported from 'src/components/dashboard/StaffNotReported';
import AnalyticService from 'src/services/AnalyticService';
import { useEffect, useState } from 'react';

const Dashboard = () => {
  const [cardData, setCardData] = useState({
    totalKaryBlmRptToday: 0,
    totalActRptWaitingToday: 0,
    totalActRptApprovedToday: 0,
    totalActRptRejectedToday: 0
  });

  useEffect(async () => {
    const { data } = await AnalyticService.sumActToday();
    setCardData(data.data);
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard | DSI Daily Reporting App</title>
      </Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} xl={3}>
          <StaffNotReported total={cardData.totalKaryBlmRptToday} />
        </Grid>
        <Grid item xs={12} sm={6} xl={3}>
          <ActivityWaiting total={cardData.totalActRptWaitingToday} />
        </Grid>
        <Grid item xs={12} sm={6} xl={3}>
          <ActivityApproved total={cardData.totalActRptApprovedToday} />
        </Grid>
        <Grid item xs={12} sm={6} xl={3}>
          <ActivityRejected total={cardData.totalActRptRejectedToday} />
        </Grid>
        <Grid item xs={12} md={12} lg={7} xl={8}>
          <ChartLineActivity sx={{ height: 400 }} />
        </Grid>
        <Grid item xs={12} md={6} lg={5} xl={4}>
          <ChartBarActivity sx={{ height: 400 }} />
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
