import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { colors, IconButton } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ActivityServices from 'src/services/ActivityServices';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const ActivityList = (props) => {
  const { openModal, triggerProcess } = props;
  const navigate = useNavigate();
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [queryParams, setQueryParams] = useState({
    limit: 100,
    dataFilter: { kegState: { filter: '' } },
    dataSort: []
  });

  // grid options
  const gridOptions = {
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      floatingFilter: true,
      suppressMenu: true,
      filterParams: { apply: false, newRowsAction: 'keep', buttons: ['apply'] }
    },

    onFilterChanged: (params) => {
      const dataFilter = params.api.getFilterModel();
      setQueryParams({ ...queryParams, dataFilter });
    },

    onSortChanged: (params) => {
      const dataSort = params.api.getSortModel();
      setQueryParams({ ...queryParams, dataSort });
    },

    onGridReady: (params) => {
      setGridApi(params.api);
    }
  };

  // set data filter
  const setDataFilter = () => {
    const { dataFilter } = queryParams;

    Object.entries(dataFilter).forEach((e) => {
      const filterInstance = gridApi.getFilterInstance(e[0]);
      const model = {
        type: 'contains',
        filter: dataFilter[e[0]].filter
      };
      filterInstance.setModel(model);
    });
    gridApi.onFilterChanged();
  };

  const approve = (data) => openModal({ action: 'APPROVE', data });
  const reject = (data) => openModal({ action: 'REJECT', data });

  const actionButton = (cell) => {
    const { data } = cell;
    return (
      <>
        {data.kegState === '' && (
          <>
            <IconButton sx={{ color: colors.red[500] }} title="Reject" onClick={() => reject(data)}>
              <CloseIcon />
            </IconButton>
            <IconButton sx={{ color: colors.green[500] }} title="Approve" onClick={() => approve(data)}>
              <CheckIcon />
            </IconButton>
          </>
        )}
        <IconButton color="inherit" onClick={() => navigate(`/app/activities/${data.kegID}`)}>
          <VisibilityIcon />
        </IconButton>
      </>
    );
  };

  const fetchData = async () => {
    try {
      const strParams = JSON.stringify(queryParams);
      const response = await ActivityServices.list(strParams);
      setRowData(response.data.data);
    } catch (error) {
      toast.error('Tidak dapat memuat data', {
        position: 'top-center',
        autoClose: 3000
      });
    }
  };

  // update data saat limit berubah
  const onLimitChanged = (e) => {
    setQueryParams({ ...queryParams, limit: e.target.value });
  };

  // set default filter dari queryParams
  useEffect(() => {
    if (gridApi) {
      setDataFilter();
    }
  }, [gridApi]);

  useEffect(() => {
    fetchData();
  }, [queryParams, triggerProcess]);

  return (
    <div className="ag-theme-alpine" style={{ height: 650 }}>
      <div style={{ width: '100%' }}>
        <h2 style={{ float: 'left', margin: 0 }}>Activty</h2>

        {/* select option limit data */}
        <select
          value={queryParams.limit}
          onChange={onLimitChanged}
          style={{
            float: 'right',
            border: 0,
            backgroundColor: 'white',
            height: 32,
            marginLeft: 5,
            marginTop: -5
          }}
        >
          <option value="100">Limit: 100</option>
          <option value="500">Limit: 500</option>
          <option value="1000">Limit: 1000</option>
          <option value="ALL">Limit: ALL</option>
        </select>

        <SyncIcon
          onClick={setRowData}
          sx={{ float: 'right', padding: 0, cursor: 'pointer' }}
        />

        <div style={{ clear: 'both' }} />
      </div>

      {/* AG Grid */}
      <AgGridReact
        gridOptions={gridOptions}
        rowData={rowData}
        frameworkComponents={{ actionButton }}
        rowSelection="single"
      >
        {/* definisi kolom */}
        <AgGridColumn
          headerName="Action"
          sortable={false}
          cellStyle={{ textAlign: 'center' }}
          filter={false}
          width={140}
          cellRenderer="actionButton"
        />
        <AgGridColumn
          field="kegState"
          width={220}
          headerName="Status"
          cellStyle={(params) => {
            const color = {
              '': colors.grey[500],
              A: colors.green[300],
              X: colors.red[300]
            }[params.value];
            return { textAlign: 'center', color };
          }}
          cellRenderer={(params) => ({
            '': 'Waiting For Approval',
            A: 'Approved',
            X: 'Rejected'
          }[params.value])}
        />
        <AgGridColumn
          field="kegStNotes"
          headerName="Status Notes"
          width={200}
          cellStyle={{ textAlign: 'center' }}
        />
        <AgGridColumn
          field="kegID"
          headerName="Activity ID"
          width={120}
          cellStyle={{ textAlign: 'center' }}
        />
        <AgGridColumn width={220} field="kegTime" headerName="Date" cellRenderer={(cell) => moment(cell.value).format('LLL')} />
        <AgGridColumn width={120} field="kegusrID" headerName="User ID" />
        <AgGridColumn field="kegusrName" headerName="User Name" />
      </AgGridReact>
    </div>
  );
};

export default ActivityList;
