import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import Account from 'src/pages/Account';
import User from 'src/pages/User';
import UserView from 'src/pages/User/View';
import Activity from 'src/pages/Activity';
import ActivityView from 'src/pages/Activity/View';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import Register from 'src/pages/Register';
import Home from 'src/pages/Home';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'users', element: <User />, children: [] },
      { path: 'users/:usrID', element: <UserView /> },
      { path: 'activities', element: <Activity /> },
      { path: 'activities/:kegID', element: <ActivityView /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'home', element: <Home /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
