import { Helmet } from 'react-helmet';
import { Container, Grid } from '@material-ui/core';
import AccountProfile from 'src/components/account/AccountProfile';
import AccountProfileDetails from 'src/components/account/AccountProfileDetails';

const Account = () => (
  <>
    <Helmet>
      <title>Account | Material Kit</title>
    </Helmet>

    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item lg={4} md={6} xs={12}>
          <AccountProfile />
        </Grid>
        <Grid item lg={8} md={6} xs={12}>
          <AccountProfileDetails />
        </Grid>
      </Grid>
    </Container>
  </>
);

export default Account;
