import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Modal from 'src/components/Modal';
import ActivityForm from 'src/components/activity/ActivityForm';
import ActivityList from 'src/components/activity/ActivityList';

const User = () => {
  const [open, setOpen] = useState(false);
  const [formConfig, setFormConfig] = useState({ action: '', data: { usrName: '', usrPswd: '', usrRole: '' } });
  const [triggerProcess, setTriggerProcess] = useState(0);

  const finishProcess = () => setTriggerProcess(triggerProcess + 1);

  const handleOpen = (conf) => {
    setOpen(true);
    setFormConfig(conf);
  };
  const handleClose = () => {
    setOpen(false);
    setFormConfig({
      action: '',
      data: { usrName: '', usrPswd: '', usrRole: '' }
    });
  };

  return (
    <>
      <Helmet>
        <title>User | Daily Reporting App</title>
      </Helmet>

      {/* tabel */}
      <ActivityList
        openModal={handleOpen}
        closeModal={handleClose}
        triggerProcess={triggerProcess}
      />

      {/* modal */}
      <Modal open={open} title="Activity Form" closeModal={handleClose}>
        <ActivityForm
          formConfig={formConfig}
          closeModal={handleClose}
          finishProcess={finishProcess}
        />
      </Modal>
    </>
  );
};

export default User;
