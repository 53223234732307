import React, { useEffect, useState } from 'react';
import moment from 'moment';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Divider,
  FormControl,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography
} from '@material-ui/core';
import {
  Check as CheckIcon,
  Person as PersonIcon,
  CalendarToday as CalendarTodayIcon
} from '@material-ui/icons';
import Encrypt from 'src/utils/Encrypt';
import ActivityServices from 'src/services/ActivityServices';
import { useRecoilValue } from 'recoil';
import { authUserState } from 'src/stores/AuthState';

const ActivityForm = (props) => {
  const { formConfig, closeModal, finishProcess } = props;
  const authUser = useRecoilValue(authUserState);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [activity, setActivity] = useState({
    kegID: '',
    kegDesc: '',
    kegStNotes: '',
    kegStTime: null,
    kegState: '',
    kegStusrID: '',
    kegTime: null,
    kegusrID: ''
  });

  const { kegID } = formConfig.data;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      kegStusrID: authUser.usrID,
      kegStNotes: ''
    },
    onSubmit: async (values) => {
      setIsSubmit(true);
      try {
        let msgSukses = '';
        if (formConfig.action === 'APPROVE') {
          await ActivityServices.approve(kegID, Encrypt(values));
          msgSukses = 'Sukses approve data';
        } else if (formConfig.action === 'REJECT') {
          await ActivityServices.reject(kegID, Encrypt(values));
          msgSukses = 'Sukses Reject data';
        }

        finishProcess();
        closeModal();
        toast(msgSukses, {
          position: 'top-center',
          autoClose: 3000
        });
      } catch (error) {
        console.log(error);
        if (error.response.status !== 500) {
          toast(`🦄 ${error.response.data.message}`, {
            position: 'top-center',
            autoClose: 3000
          });
        }
      }
      setIsSubmit(false);
    },
    validationSchema: yup.object({
      kegStusrID: yup.string().required(),
      kegStNotes:
        formConfig.action === 'REJECT'
          ? yup.string().required('For rejected action notes is required')
          : yup.string()
    })
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ActivityServices.get(kegID);
        setActivity(response.data.data);
        setIsLoading(false);
        setIsSubmit(false);
      } catch (error) {
        toast.error('Tidak dapat memuat data', {
          position: 'top-center',
          autoClose: 3000
        });
      }
    };

    if (kegID) fetchData();
  }, [formConfig]);

  return isLoading ? (
    'loading ...'
  ) : (
    <>
      <List>
        <ListItem sx={{ p: 0 }}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={activity.kegusrID} />
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListItemIcon>
            <CalendarTodayIcon />
          </ListItemIcon>
          <ListItemText secondary={moment(activity.kegTime).format('LLL')} />
        </ListItem>
      </List>

      <Typography variant="h6" sx={{ mt: 2 }}>
        Description
      </Typography>
      <Typography>{activity.kegDesc}</Typography>

      <Typography variant="h6" sx={{ mt: 2 }}>
        Pictures
      </Typography>
      <Box>
        <ImageList cols={6}>
          {activity.pic.map((pic) => (
            <>
              <ImageListItem key={pic.picID}>
                <img src={pic.picPath} alt={pic.picID} loading="lazy" />
              </ImageListItem>
            </>
          ))}
        </ImageList>
      </Box>

      <Divider sx={{ mt: 3 }} />

      <Typography variant="h6" sx={{ mt: 2 }}>
        {`Please fill the notes for ${formConfig.action} data.`}
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <TextField
            multiline
            id="kegStNotes"
            name="kegStNotes"
            rows={3}
            value={formik.values.kegStNotes}
            onChange={formik.handleChange}
            error={formik.touched.kegStNotes && Boolean(formik.errors.kegStNotes)}
            helperText={formik.touched.kegStNotes && formik.errors.kegStNotes}
          />
        </FormControl>

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={closeModal}
            style={{ float: 'left', width: 110 }}
            variant="text"
          >
            CANCEL
          </Button>
          <Button
            type="submit"
            disabled={isSubmit}
            style={{ float: 'right', width: 110 }}
            variant="contained"
            startIcon={<CheckIcon />}
          >
            OK
          </Button>
        </Box>
      </form>
    </>
  );
};
export default ActivityForm;
